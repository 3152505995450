
import { defineComponent, onMounted, computed, ref, reactive } from "vue";
import Vue from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import CommonService from "@/core/services/CommonService";
import moment from "moment";

export default defineComponent({
  name: "student-list",
  components: {
  },
  setup() {
    const router = useRouter();
    const studentListData : any[] = reactive([]);
    const studentStatuses = ref<any>([]);
    const filters = reactive({status : [], category: [], balance:[]});
    const admissionCategories = ref<any>([]);
    const balanceSearchOptions = [{text:'> 0',value:'> 0'},{text: '= 0',value:'= 0'},{text: '< 0',value:'< 0'}];

    const getStudentsSummary = async () => {
        var today = new Date();
        await new Promise((resolve, reject) => {
            ApiService.setHeader();            
            ApiService.get("student/date/"+today)
                .then(({ data }) => {
                    Object.assign(studentListData,data.data.sort((a, b) => a.status.localeCompare(b.status)));
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    console.log('getStudentsSummary error => '+response.data.errors);
                    reject(new Error ('getStudentsSummary error => '+response.data.errors));
                });
        });
    }

    const pageSize = ref(20);
    const pageSizes = computed(()=>{
      let sizes = [20];
      let curr_length = getFilteredStudentList().length;

      if(curr_length > 20){
        sizes.push(50);
        if(curr_length >50){
          let i = 0;
          while(i<curr_length){
            i+=100;
            sizes.push(i);
          }
        }
      }

      return sizes;
    })

    const handleSizeChange = (val: number) => {
      pageSize.value = val; 
    }

    let page = reactive({pageValue : 1});

    const pagedTableData = computed(()=>{
      return getFilteredStudentList().slice(pageSize.value * page.pageValue - pageSize.value, pageSize.value * page.pageValue);
     })

    const getFilteredStudentList = () => {
        return studentListData.filter(data => (!search 
                                          || (data.first_name + " " + data.last_name).toLowerCase().includes(search.searchValue.toLowerCase())
                                          || data.student_id?.toLowerCase().includes(search.searchValue.toLowerCase())
                                          || data.phone?.toLowerCase().includes(search.searchValue.toLowerCase())
                                          || data.email?.toLowerCase().includes(search.searchValue.toLowerCase())
                                          || data.admission_category?.toLowerCase().includes(search.searchValue.toLowerCase())
                                          || data.nationality?.toLowerCase().includes(search.searchValue.toLowerCase())
                                          || (data.advisor && (data.advisor?.first_name + " "+ data.advisor?.last_name).toLowerCase().includes(search.searchValue.toLowerCase()))
                                          || data.sevis_no?.toLowerCase().includes(search.searchValue.toLowerCase())
                                          || (data.address && data.address?.state?.toLowerCase().includes(search.searchValue.toLowerCase()))
                                          || (data.agent && data.agent?.name.toLowerCase().includes(search.searchValue.toLowerCase())))
                                          && (filters.status.length > 0 ? filters.status.some(x=>x == data.status) : true)
                                          && (filters.category.length > 0 ? filters.category.some(x=>x == data.admission_category) : true)
                                          && (filters.balance.length > 0 ? filters.balance.some(x=>filterBalance(x,data.balance)) : true));
    }

    const search = reactive({searchValue : ''});

    const setPage = (val) => {
      page.pageValue = val;
    }

    const noOfActiveStudents = computed(()=>{
      return studentListData.filter(data => data.status =='Active').length;
    })

    const noOfActiveF1 = computed(()=>{
      return studentListData.filter(data => data.status =='Active' && data.admission_category == 'F-1').length;
    })

    const noOfActiveNonI20 = computed(()=>{
      return studentListData.filter(data => data.status =='Active' && data.admission_category == 'Non I-20').length;
    })

    const totalBalance = computed(()=>{
      return studentListData.reduce(function(a, b){return +a + +b['balance'];}, 0);
    })


    const selectStudent= (row) =>{
      router.push({ name: "student-profile", params: { id: row.id } });
    }

    const openCreateStudent= () =>{
      router.push({ name: "student-profile", params: { id: 0 } });
    }

    const handleFilterChange = (e) =>{
      const propertyName = Object.getOwnPropertyNames(e)[0];
      const value = e[propertyName];

      if(propertyName == 'status-column'){
        filters.status = value;
      }

      if(propertyName == 'category-column'){
        filters.category = value;
      }

      if(propertyName == 'balance-column'){
        filters.balance = value;
      }
    }

    const filterBalance = (filter, balance) => {
        if(filter == '> 0'){
            return balance > 0;
        }
        else if(filter == '= 0'){
            return balance == 0;
        }
        else if(filter == '< 0'){
            return balance < 0;
        }
    }

    const getTypes = async() => {
      return new Promise((resolve, reject) => { 
        ApiService.setHeader();
        ApiService.get("student/types")
        .then(({ data }) => {
            console.log(data);
            data.studentStatuses.forEach(element => {
              var statusObj = {};
              statusObj['text'] = element;
              statusObj['value'] = element;
              studentStatuses.value.push(statusObj);
            });
            data.admissionCategories.forEach(element =>{
              var categoryObj = {};
              categoryObj['text'] = element;
              categoryObj['value'] = element;
              admissionCategories.value.push(categoryObj); 
            });
            resolve(data.data);
        })
        .catch(({ response }) => {
          reject('getTypes student error => '+response.data.errors);
          console.log('getTypes student error => '+response.data.errors);
        });
      });
    }

    const downloadStudents = ()=>{
      let currentStudentList = getFilteredStudentList().map(x=>{ return { 
        StudentId: x.student_id, 
        SevisNo : x.sevis_no,
        FirstName : x.first_name,
        LastName : x.last_name,
        Phone : x.phone, 
        Email : x.email,
        DOB : x.birth_date ? moment(x.birth_date).format('MM/DD/YYYY'): '',
        Address : x.address? x.address?.address1 + " " + x.address?.address2 + " " + x.address?.city + " " + x.address?.state + " " + x.address?.zipcode : '',
        Nationality : x.nationality,
        Category : x.admission_category,
        Agent : x.agent? x.agent?.name : '',
        Advisor : x.advisor? x.advisor?.first_name + " "+ x.advisor?.last_name : '',
        Status : x.status,
        StartDate : x.start_date ? moment(x.start_date).format('MM/DD/YYYY'): '',
        EndDate : x.end_date ? moment(x.end_date).format('MM/DD/YYYY'): '',
        AttendanceRate : x.attendance_rate,
        Balance : x.balance        
      };});
      let today = new Date();
      let fileName = 'Student List '+ today.toLocaleString();
      CommonService.exportToCsv(fileName, currentStudentList);
    }

    onMounted(() => {
      getTypes();
      setCurrentPageBreadcrumbs("Student List", ["Student"]);
      getStudentsSummary();
    });

    return {
      selectStudent,
      studentListData,
      pagedTableData,
      pageSize,
      pageSizes,
      search,
      studentStatuses,
      admissionCategories,
      balanceSearchOptions,
      downloadStudents,
      handleSizeChange,
      getFilteredStudentList,
      noOfActiveStudents,
      noOfActiveF1,
      noOfActiveNonI20,
      totalBalance,
      setPage,
      openCreateStudent,
      filters,
      handleFilterChange
    };
  },
});
